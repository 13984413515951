import React from 'react';

import {
    HeroBanner,
    CaseHeader,
    TextContent,
    Gallery,
    GalleryLayout,
    Team,
    Testimonial,
    TestimonialType,
    Image,
    Container,
} from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import Seo from '../../components/SEO';
import { ReferenceCategory } from '../../constants';

import socialPreview from '../../../static/social-previews/edoklady.png';

const CASE_ID = 'edoklady';
const MODULE_NAME = 'edoklady';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    text: '#0F1E33',
    violetBg: '#F0D9F4',
    blueBg: '#D7EEFF',
    blueText: '#88C1E9',
    white: '#FFF',
    teamBg: '#0F1E33',
    teamTitle: '#88C1E9',
    transparent: 'transparent',
};

const team = [
    { department: 'references.case.team.management', names: ['Soňa Daneshjoová'] },
    {
        department: 'references.case.team.android',
        names: ['Jan Mottl', 'Mykhailo Liutov'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Rostislav Babáček', 'Alexander Grigorov'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Mariya Vorona'],
    },
];

const Edoklady = () => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero-main.png`,
                    imgStyle: { objectFit: 'contain', objectPosition: 'bottom center' },
                    style: {
                        marginTop: '3rem',
                    },
                    alt: `case.${CASE_ID}.hero.project`,
                },
            }}
            backgroundImage={{
                src: `${IMAGE_PATH}/hero-bg.png`,
                imgStyle: { objectFit: 'cover', objectPosition: 'center center' },
            }}
            headerTheme="dark"
            background={`linear-gradient(135deg, ${colors.blueBg} 0%, ${colors.violetBg} 100%)`}
            textColor={colors.text}
        />
        {/* Introduction */}
        <TextContent
            headerTheme="dark"
            textColor={colors.text}
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.DIGITALIZATION,
                outputs: [
                    'references.case.output.iosModule',
                    'references.case.output.androidModule',
                    'references.case.output.testing',
                ],
            }}
            background={colors.white}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.EQUAL}
            fullHeight={false}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/01-main.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                        },
                        style: {
                            width: '80%',
                            maxWidth: '86rem',
                        },
                    },
                },
            ]}
            background={`linear-gradient(180deg, ${colors.white} 0%, ${colors.violetBg} 100%)`}
        />

        {/* Challenge */}
        <TextContent
            headerTheme="dark"
            textColor={colors.text}
            content={{
                section: `case.${CASE_ID}.section2.name`,
                title: `case.${CASE_ID}.section2.title`,
                text: `case.${CASE_ID}.section2.text`,
                image: {
                    src: `${IMAGE_PATH}/text02-img.png`,
                    style: {
                        position: 'absolute',
                        top: 0,
                        right: '2rem',
                        width: '15%',
                        maxWidth: '10.5rem',
                        overflow: 'visible',
                    },
                    imgStyle: {
                        overflow: 'visible',
                        objectPosition: 'top 10% right 30%',
                    },
                },
            }}
            background={colors.violetBg}
            extendMainContainer={{
                paddingBottom: 0,
            }}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="dark"
            fullHeight={false}
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/02-main.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'left bottom',
                            overflow: 'visible',
                        },
                        style: {
                            maxWidth: '86rem',
                            width: '70%',
                            overflow: 'visible',
                            minHeight: 'unset',
                            paddingRight: '12%',
                            marginBottom: '-2rem',
                        },
                    },
                },
            ]}
            backgroundImage={{
                src: `${IMAGE_PATH}/02-bg.png`,
                imgStyle: {
                    objectFit: 'fill',
                    objectPosition: 'top',
                    overflow: 'visible',
                },
                style: {
                    overflow: 'visible',
                },
            }}
        />

        {/* Solution */}
        <TextContent
            headerTheme="dark"
            textColor={colors.text}
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
            background={colors.transparent}
        />

        {/* @ts-expect-error */}
        <Container
            background={`linear-gradient(180deg, ${colors.white} 0%, ${colors.blueBg} 50%, ${colors.white} 100%)`}
        >
            <Image
                src={[
                    { src: `${IMAGE_PATH}/03-main-mobile.png` },
                    { src: `${IMAGE_PATH}/03-main.png`, media: '(min-width: 800px)' },
                    { src: `${IMAGE_PATH}/03-main-mobile-en.png`, locale: 'en' },
                    { src: `${IMAGE_PATH}/03-main-en.png`, media: '(min-width: 800px)', locale: 'en' },
                ]}
                imgStyle={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                }}
                style={{
                    margin: '0 auto',
                    width: '100%',
                    maxWidth: '86rem',
                }}
            />

            <Image
                src={`${IMAGE_PATH}/text04-img.png`}
                style={{
                    position: 'absolute',
                    bottom: '-8%',
                    right: '10%',
                    width: '15%',
                    maxWidth: '10.5rem',
                    overflow: 'visible',
                }}
                imgStyle={{
                    overflow: 'visible',
                    objectPosition: 'bottom 0 right 30%',
                }}
            />
        </Container>

        {/* Interesting facts */}
        <TextContent
            headerTheme="dark"
            textColor={colors.text}
            background={colors.transparent}
            content={{
                section: `case.${CASE_ID}.section4.name`,
                title: `case.${CASE_ID}.section4.title`,
                text: `case.${CASE_ID}.section4.text`,
            }}
            extendMainContainer={{
                whiteSpace: 'pre-wrap',
            }}
        />
        {/* @ts-expect-error */}
        <Testimonial
            headerTheme="dark"
            background={`linear-gradient(180deg, ${colors.blueBg} 0%, ${colors.violetBg} 100%)`}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/img_person_edoklady.jpg`,
                    },
                },
            }}
            type={TestimonialType.PROFILE_RIGHT}
            textColor={colors.text}
        />
        {/* @ts-expect-error */}
        <Team
            headerTheme="light"
            content={team}
            background={colors.teamBg}
            textColor={colors.white}
            titleColor={colors.blueText}
        />
    </>
);

export default Edoklady;
